<template>
  <section
    class="unit-stage-container"
    :class="{ isHovered: unit.hovered }"
    :style="[
      { background: getStageColor(unit.stage_value) },
      { border: `1px solid ${getStageColor(unit.stage_value)}` },
    ]"
    @mouseenter="unit.hovered = true"
    @mouseleave="unit.hovered = false"
  >
    <p class="-ltb" v-show="!unit.hovered">{{ unit.stage_value }}</p>
    <div :class="'slide-in'" v-show="unit.hovered">
      <p class="-stb">
        {{ unit.stage_value }}: {{ getStageName(unit.stage_value) }}
      </p>
    </div>
  </section>
</template>

<script>
import { getStageColor, getStageName } from "../../helpers/stageGetters";
export default {
  props: ["unit"],
  methods: {
    getStageColor(unitStage) {
      return getStageColor(unitStage);
    },
    getStageName(unitStage) {
      return getStageName(unitStage);
    },
  },
};
</script>

<style scoped>
.unit-stage-container {
  width: var(--unit-stage-width);
  height: calc(100% - 8px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 350ms;
  /* box-shadow: inset 4px 0px 4px 1px rgba(0, 0, 0, 0.2); */
  backdrop-filter: blur(8px);
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}
.isHovered {
  width: 240px;
  padding-inline: 8px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  /* white-space: nowrap; */
}
.slide-in {
  animation: slide-from-right 1200ms;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

@keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(120%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-from-left {
  0% {
    opacity: 0;
    transform: translateX(-120%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1279px) {
  .unit-stage-container {
    height: 100%;
  }
  .isHovered {
    width: 180px;
    padding-inline: 4px;
    /* white-space: nowrap; */
  }
  .-xltb {
    font-size: 0.875rem;
  }
  .-ntb {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 719px) {
  .unit-stage-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .slide-in {
    animation: slide-from-left 1200ms;
  }
}
</style>