<template>
  <main class="project-units-container">
    <section class="unit-container" v-for="unit in units" :key="unit">
      <div class="flex">
        <Unit :unit="unit" @deleteUnit="deleteUnit" />
      </div>

      <div class="flex">
        <ProjectOverviewTable />
      </div>
      <div class="flex"><ProjectKeyInformation /></div>

      <div><UnitCurrentStage :unit="unit" /></div>
    </section>
  </main>
</template>

<script>
import Unit from "../units/Unit.vue";
import ProjectOverviewTable from "../units/ProjectOverviewTable.vue";
import ProjectKeyInformation from "../units/ProjectKeyInformation.vue";
import UnitCurrentStage from "../units/UnitCurrentStage.vue";

export default {
  data() {
    return {};
  },
  props: ["units"],
  components: {
    Unit,
    ProjectOverviewTable,
    ProjectKeyInformation,
    UnitCurrentStage,
  },
  methods: {
    deleteUnit(id) {
      this.$emit("deleteUnit", id);
    },
  },
};
</script>

<style scoped>
.project-units-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding-top: 8px;
  padding-right: 1rem;
  gap: 1rem;
  position: relative;
  overflow-x: hidden;
  --unit-container-height: 220px;
  --unit-stage-width: 32px;
}
.unit-container {
  position: relative;
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: calc(1rem + var(--unit-stage-width));
  height: var(--unit-container-height);
  background: var(--white1);
  border: 1px solid var(--gray6);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

hr {
  border: none;
  border-right: 1px solid var(--gray6);
  height: 100%;
  padding-inline: 4px;
}
.unit-container div {
  height: 100% !important;
}
.unit-container > div:nth-child(1) {
  min-width: 380px;
}
.unit-container > div:nth-child(2) {
  width: 40%;
}
.unit-container > div:nth-child(3) {
  width: 400px;
}
.unit-container > div:nth-child(4) {
  position: absolute;
  top: 0;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
}
@media screen and (max-width: 1279px) {
  .project-units-container {
    padding-right: 0;
  }
  .unit-container {
    flex-flow: column;
    height: auto;
    padding-right: 0;
    position: relative;
    z-index: 1;
  }

  .unit-container > div:nth-child(1) {
    position: relative;
    height: 200px !important;
    padding-bottom: 8px;
  }

  .unit-container > div:nth-child(2),
  .unit-container > div:nth-child(3) {
    height: auto !important;
    width: 100% !important;
  }

  .unit-container > div:nth-child(4) {
    height: 100px !important;
    top: 8px;
    /* right: calc(100% - var(--unit-stage-width)); */
  }
}

@media screen and (max-width: 719px) {
  .unit-container > div:nth-child(4) {
    position: absolute;
    z-index: 1;
    height: 100px !important;
    top: 50px;
    /* right: calc(100% - var(--unit-stage-width)); */
    left: 8px;
  }
}
</style>