<template>
  <main class="unit-wrapper" @mouseleave="closeSecondaryMenu">
    <section class="unit-image-wrapper" @click="enterUnit(unit)">
      <div class="unit-image"></div>
      <!-- :style="[
          unit.unit_image
            ? `background-image: url('${unit.unit_image}')`
            : `background-image: var(--project-thumbnail); filter: hue-rotate(90deg);`,
        ]" -->
    </section>

    <section class="unit-info-wrapper">
      <div class="flex-column gap8">
        <p class="-stb">{{ unit.unit_reference }}</p>
        <p class="-stb">{{ unit.unit_title }}</p>
      </div>

      <div
        class="flex width100 gap8 align-end padding-block-4"
        title="Chosen Template"
      >
        <div
          class="template-icon"
          :class="`i-${unit.template.toLowerCase()}`"
        ></div>
        <p class="-xstb">{{ unit.template }}</p>
      </div>

      <p
        class="-ntb submenu-toggler"
        title="More options"
        @click="toggleSubmenu"
      >
        . . .
      </p>
      <section
        class="secondary-menu"
        v-show="unit.secondaryMenu"
        ref="secondaryMenu"
      >
        <div class="secondary-menu-item">
          <p class="-xstr">Unit Details</p>
        </div>
        <div class="secondary-menu-item delete-p" @click="deleteUnit(unit.id)">
          <p class="-xstr">Delete Unit</p>
        </div>
        <div class="secondary-menu-item" @click="unit.secondaryMenu = false">
          <p class="-xstr">Close</p>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import { deleteUnit } from "../../services/requests/post/projects";
export default {
  props: ["unit"],
  emits: ["deleteUnit"],
  data() {
    return {
      isToggled: false,
    };
  },
  methods: {
    async enterUnit(unit) {
      await this.$store.dispatch("unitModule/unit", unit);
      console.log("unit set successfully: ", this.$store.state.unitModule.unit);
      await this.$store.dispatch(
        "setCurrentStage",
        this.$store.state.unitModule.unit.stage_value
      );

      this.$router.push({ name: "Dashboard" });
    },
    async deleteUnit(id) {
      const res = await deleteUnit(id);
      if (res == 200) {
        this.$emit("deleteUnit", id);
      }
    },
    toggleSubmenu() {
      this.unit.secondaryMenu = !this.unit.secondaryMenu;
      console.log(this.unit);
    },
    closeSecondaryMenu() {
      if (!this.unit.secondaryMenu) return;

      //set timeout to allow for click event to fire
      setTimeout(() => {
        this.unit.secondaryMenu = false;
        this.$refs.secondaryMenu.classList.remove("slide-out");
      }, 200);

      this.$refs.secondaryMenu.classList.add("slide-out");
    },
  },
};
</script>

<style scoped>
.unit-wrapper {
  display: flex;
}
.unit-image-wrapper {
  width: 260px;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.unit-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 600ms;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-image: var(--project-thumbnail) !important;
  filter: hue-rotate(90deg);
}
.unit-image:hover {
  scale: 1.05;
}
.unit-image:hover::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "Click to enter unit";
  font-family: "Montserrat-Bold";
  color: #ffffff !important;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  animation: slide-up 400ms;
}
.unit-info-wrapper {
  position: relative;
  max-width: 160px;
  padding-inline: 8px;
  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 8px;
}
.template-icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.submenu-toggler {
  user-select: none;
  cursor: pointer;
  width: max-content;
  padding-inline: 2px;
}

.secondary-menu {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 8px;
  padding: 1px;
  background: rgb(226, 116, 236);
  background: linear-gradient(
    29deg,
    rgb(255, 88, 88) 10%,
    rgb(245, 143, 182) 50%,
    rgb(226, 116, 236) 90%
  );
  border-radius: 2px;
  cursor: pointer;
  animation: slide-in 200ms;
}
.slide-out {
  animation: slide-out 200ms forwards;
}
.secondary-menu-item {
  padding-inline: 8px;
  padding-block: 4px;
  /* background: linear-gradient(
    34deg,
    rgba(17, 17, 17, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  ); */
  background: rgba(0, 0, 0, 0.8);
  margin-bottom: 1px;
  text-align: center;
  color: var(--white1);
}

.secondary-menu-item:last-child {
  margin-bottom: 0px;
  color: var(--gray4);
}

.secondary-menu-item:hover {
  color: var(--secondary);
}

.delete-p:hover {
  color: salmon;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes slide-in {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-120%);
  }
}
@keyframes fade-bg {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.2);
  }
}
@media screen and (max-width: 499px) {
  .unit-image-wrapper {
    width: 224px;
  }
}
@media screen and (max-width: 374px) {
  .unit-image-wrapper {
    width: 180px;
    height: 144px;
    align-self: center;
  }

  @keyframes slide-in {
    from {
      transform: translateX(120%);
    }
    to {
      transform: translateX(0%);
    }
  }
}
</style>