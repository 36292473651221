<template>
  <main class="chart-wrapper">
    <section id="stacked-chart">
      <apexchart
        type="bar"
        height="220"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      //series[0] = tasks; series[1] = delvierables
      series: [
        {
          name: "Ongoing",
          data: [14, 22],
        },
        {
          name: "Awaiting Verification",
          data: [5, 12],
        },
        {
          name: "Completed",
          data: [12, 10],
        },

        {
          name: "Upcoming",
          data: [9, 21],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 12,
            barHeight: "33%",
          },
        },
        title: {
          text: "Progress Status",
          style: {
            fontFamily: "Montserrat-Regular",
            fontSize: "16px",
            color: "var(--black3)",
          },
        },
        xaxis: {
          type: "category",
          categories: ["Tasks", "Deliverables"],
          tickAmount: "dataPoints",

          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        grid: {
          show: false,
        },
        colors: ["#77CCEE", "#bc77ee", "#8fee77", "#fce83a"],
      },
    };
  },
};
</script>

<style scoped>
.chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
#stacked-chart {
  width: 100%;
  height: 100%;
  margin-top: -1.5rem;
  position: relative;
  z-index: 1;
  scale: 0.9;
}
.stackType-wrapper {
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;
}
#stackType-toggler {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  cursor: pointer;
  outline: 1px solid var(--gray5);
}
.stackType-wrapper > .-xstr {
  user-select: none;
}
</style>