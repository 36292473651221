<template>
  <main class="project-units-container">
    <section class="skip-container">
      <div class="bg">
        <p class="-ntb" @click="skipUnit">Skip Unit Creation</p>
      </div>

      <section class="text-wrapper">
        <p class="-stb">Welcome to your new Project</p>
        <p class="-xstr" style="color: var(--gray3)">
          We're glad to have you onboard. Need to create a new unit? Click on
          create!
        </p>
      </section>

      <section class="buttons-container">
        <div class="btn-secondary" @click="skipUnit">
          <p class="-xstb">Skip</p>
        </div>
        <div class="btn-primary" @click="openedModal = true">
          <p class="-xstb">Create unit</p>
        </div>
      </section>
    </section>
  </main>

  <Modal
    v-if="openedModal"
    :setModal="setModal"
    @closeModal="openedModal = false"
  />
</template>

<script>
import Modal from "../../views/Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      openedModal: false,
      setModal: "createUnit",
    };
  },
  methods: {
    skipUnit() {
      alert("Under development...");
    },
  },
};
</script>

<style scoped>
.project-units-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 8px;
  gap: 16px;
}

.skip-container {
  background: var(--white1);
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 4px;
}
.bg {
  background: rgb(51, 57, 142);
  background: radial-gradient(
    circle,
    rgba(51, 57, 142, 1) 25%,
    rgba(21, 22, 59, 1) 100%
  );
  height: 156px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.bg p {
  color: var(--white1);
  background: rgba(255, 255, 255, 0.25);
  padding-inline: 16px;
  padding-block: 4px;
  border-radius: 16px;
  cursor: pointer;
  transition: 200ms ease-in-out;
}
.bg p:hover {
  color: var(--secondary);
  padding-inline: 32px;
  /* padding-block: 8px; */
}
.text-wrapper {
  padding: 8px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.buttons-container {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
}

.btn-primary,
.btn-secondary {
  height: 100%;
  width: 100%;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  transition: background-position 1s, box-shadow 800ms;
  background-size: 500% auto;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  padding-block: 8px;
}
.btn-primary {
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  color: var(--white1);
}
.btn-secondary {
  background-image: linear-gradient(
    to right,
    var(--secondary) 0%,
    var(--secondary-highlight) 51%,
    var(--secondary) 100%
  );
  color: var(--white1);
}
.btn-primary:active,
.btn-secondary:active {
  scale: 0.95;
}

.btn-primary:hover,
.btn-secondary:hover {
  background-position: right center;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
@media screen and (max-width: 1279px) {
  .skip-container {
    width: 80%;
    max-width: 540px;
  }
}
@media screen and (max-width: 599px) {
  .skip-container {
    width: 100%;
    border-radius: 4px;
  }
}
</style>