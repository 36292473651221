<template>
  <main class="units-view" v-if="API_REQUEST_STATE">
    <section class="project-info">
      <ProjectInfo :unitsLength="units.length" />
    </section>
    <section class="new-unit"><NewUnit /></section>
    <section :class="units.length ? 'project-view' : 'no-units-view'">
      <ProjectView
        :units="units"
        :stages="stages"
        v-if="stages.length"
        @deleteUnit="deleteUnit"
      />
    </section>

    <section class="right-side">
      <ProjectDate />
      <SkipUnitCreation v-if="!units.length" />
    </section>

    <section class="project-view-summary">
      <ProjectSubMenu />
    </section>
  </main>
</template>

<script>
import ProjectInfo from "../components/units/ProjectInfo.vue";
import SearchBar from "../components/utils/BaseSearchBar.vue";
import NewUnit from "../components/units/NewUnit.vue";
import SkipUnitCreation from "../components/units/SkipUnitCreation.vue";
import ProjectSubMenu from "../components/projects/ProjectSubMenu.vue";
import ProjectView from "../views/ProjectView.vue";
import ProjectDate from "../components/navigation/mscopeTopnav/ProjectDate.vue";
import { getProject } from "../services/requests/get/projects";
export default {
  components: {
    ProjectInfo,
    SearchBar,
    NewUnit,
    ProjectSubMenu,
    ProjectView,
    SkipUnitCreation,
    ProjectDate,
  },
  data() {
    return {
      PROJECTID: parseInt(this.$route.params.id),
      units: [],
      stages: [],
      API_REQUEST_STATE: false,
    };
  },
  async created() {
    const res = await getProject(this.PROJECTID);
    this.API_REQUEST_STATE = true;

    if (res.status != 200) return;
    this.setUnits(res.data.units);

    console.log("units: ", this.units);

    //DELETE ME AFTER PULL
    this.units.forEach((unit) => {
      // unit.stage_value = unit.unit_current_stage.stage_value;
      //esperar resolução do backend
      unit.stage_value = 0;
      unit.template = "Building";
      console.log(unit);
    });
    //#####################
    this.setStages(res.data.stages);
  },
  methods: {
    setStages(stagesArray) {
      this.stages = stagesArray;
      this.$store.dispatch("stages", this.stages);
      console.log("stages set successfully: ", this.$store.state.stages);
    },
    setUnits(unitsArray) {
      this.units = unitsArray;
      console.log("units set successfully");
    },
    deleteUnit(id) {
      this.units = this.units.filter((unit) => unit.id != id);
    },
  },
};
</script>

<style scoped>
.units-view {
  display: grid;
  height: 100%;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-rows: repeat(12, 1fr); */
  padding-inline: 4px;
  padding-top: 4px;
  overflow: auto;
  position: relative;
  z-index: 1;
  background-image: var(--appBg) !important;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}
.project-info {
  grid-column: 1 / span 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 16px;
  padding-top: 4px;
  align-self: start;
  position: relative;
  z-index: 0;
}
.new-unit {
  grid-column: 1 / span 1;
  grid-row: span 1;
}
.project-view-summary {
  grid-column: 1 / span 1;
  grid-row: span 3;
}
.project-view {
  grid-column: 2 / span 5;
  grid-row: 1 / span 12;
}
.no-units-view {
  grid-column: 2 / span 4;
  grid-row: 1 / span 12;
}
.delete-unit {
  grid-column: 1 / span 1;
  grid-row: 12 / span 1;
  align-self: end;
  margin-bottom: 8px;
}
.right-side {
  grid-column: 6 / span 1;
  padding-right: 8px;
  grid-row: 1 / span 12;
  min-width: 220px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 4px;
  gap: 8px;
  /* overflow: auto; */
}
@media screen and (max-width: 1279px) {
  .units-view {
    display: flex;
    flex-direction: column;
    /* padding: 32px; */
    /* overflow-x: hidden; */
    padding-block: 8px;
  }
  .project-info {
    display: flex;
    flex-flow: row;
    width: 100%;
    order: 1;
    justify-content: center;
    align-items: center;
  }
  .new-unit {
    order: 5;
  }
  .project-view {
    order: 3;
    width: 80%;
    align-self: center;
  }
  .right-side {
    position: relative;
    order: 2;
    width: 100%;
    justify-self: start;
    align-self: center;
  }
  .project-view-summary {
    order: 6;
    align-self: center;
  }
  .delete-unit {
    order: 7;
  }
  .new-unit {
    align-self: center;
  }

  .delete-unit {
    align-self: center;
  }
}
@media screen and (max-width: 767px) {
  .project-view {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .units-view {
    padding: 0;
    margin: 0;
  }
  .project-info {
    flex-flow: column;
  }
}
</style>