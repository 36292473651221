import store from "../store";

export const getStageColor = (unitCurrentStage) => {
  if (!store.state.stages) return;

  const stage = store.state.stages.find((stage) => {
    return stage.stage_value === unitCurrentStage;
  });

  if (!stage) return;

  return `${stage.color_code}99`;
};

export const getStageName = (unitCurrentStage) => {
  if (!store.state.stages) return;

  const stage = store.state.stages.find((stage) => {
    return stage.stage_value === unitCurrentStage;
  });

  if (!stage) return;

  return stage.stage_name;
};
